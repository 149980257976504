import { render, staticRenderFns } from "./ProductPromotionDetail.vue?vue&type=template&id=d124157a&scoped=true&"
import script from "./ProductPromotionDetail.vue?vue&type=script&lang=js&"
export * from "./ProductPromotionDetail.vue?vue&type=script&lang=js&"
import style0 from "./ProductPromotionDetail.vue?vue&type=style&index=0&id=d124157a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d124157a",
  null
  
)

export default component.exports